import useTableOptions, {
  TableOptions
} from '@/@core/helpers/table-options';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import { reactive, toRefs } from '@vue/composition-api';
import { MaybeRef } from '@vueuse/core';
import { useClassificationStore } from '../../services/store';

type ClassificationModelState = {
  headers: any[];
  isLoading: boolean;
  showFormDialog: boolean;
  showDeleteDialog: boolean;
  isFormValid: boolean;
  formData: any;
  search: string;
};

const state = reactive<ClassificationModelState>({
  headers: [
    {
      text: 'Kode',
      value: 'code'
    },
    {
      text: 'KBLI',
      value: 'title'
    },
    {
      text: 'Keterangan',
      value: 'description'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      width: '1%'
    }
  ],
  isLoading: false,
  showFormDialog: false,
  showDeleteDialog: false,
  isFormValid: false,
  formData: null,
  search: ''
});

export default function useClassificationModel() {
  const store = useClassificationStore();

  const onGetManyClassification = async (
    options: MaybeRef<TableOptions>,
    search?: MaybeRef<string>
  ) => {
    state.isLoading = true;

    try {
      state.isLoading = true;
      await store.getManyClassification(options, search);
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onCreateClassification = async (data: any) => {
    state.isLoading = true;

    try {
      await store.postClassification(data);
      useUiStateStore().createSnackbar({
        message: `KBLI ${data.code} - ${data.title} berhasil ditambahkan.`
      });

      state.showFormDialog = false;
      await onGetManyClassification(useTableOptions());
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onUpdateClassification = async (data: any) => {
    state.isLoading = true;

    try {
      await store.putClassification(data);
      useUiStateStore().createSnackbar({
        message: `KBLI ${data.code} - ${data.title} berhasil diubah.`
      });
      state.showFormDialog = false;
      await onGetManyClassification(useTableOptions());
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const createClassification = () => {
    state.formData = null;
    state.showFormDialog = true;
  };

  const editClassification = (classification: any) => {
    state.formData = { ...classification };
    state.showFormDialog = true;
  };

  const deleteClassification = (classification: any) => {
    state.formData = classification;
    state.showDeleteDialog = true;
  };

  const onDeleteClassification = async () => {
    state.isLoading = true;

    try {
      await store.deleteClassification(state.formData);
      const data: any = state.formData || {};
      useUiStateStore().createSnackbar({
        message: `KBLI ${data.code} - ${data.title} berhasil dihapus.`
      });
      state.showDeleteDialog = false;
      state.formData = null;
      await onGetManyClassification(useTableOptions());
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onSubmitForm = () => {
    const isEdit = (state.formData as any)?.id;

    if (isEdit) onUpdateClassification(state.formData);
    else onCreateClassification(state.formData);
  };

  return {
    ...toRefs(state),
    store,
    onGetManyClassification,
    onSubmitForm,
    onDeleteClassification,
    deleteClassification,
    editClassification,
    createClassification
  };
}

export { useClassificationModel };
